.feature{
    max-width: 1000px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto 3rem auto;
}

.feature img{
    max-width: 100%;
}

.feature > :first-child {
    flex-basis: 350px;
    flex-grow: 1;
}

.feature > :last-child {
    flex-basis: 350px;
    flex-grow: 1;
}

.content {
    padding: 0 1rem;
}

.feature__title {
    margin: 0;
}

.feature__text {
    margin: 0;
}

@media (min-width: 700px) {
    .featuredItems :nth-child(2) {
        flex-direction: row-reverse;
    }

    .content {
        padding: 0 2rem;
    }
}