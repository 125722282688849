.banner{
    height: 450px;
    width:100%;
}

.scaling img {
    width: 100vw;
    height: 500px;
    object-fit: cover;
    object-position: 50% 0%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

@media (min-width:700px) {
    .banner {
        height: 400px;
    }
}