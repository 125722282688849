#footer{
    margin-top: 20px;
    padding: 30px 0 30px 0;
    /* display: flex; */
    height: 250px;
    text-align: center;
    /* align-items: center; */
    width: 100%;
    /* background-color: antiquewhite; */
}

.faIcon{
    padding:20px;
    /* color: antiquewhite; */
    /* color: #b86d29; */
    color: #a97142;
}

/* .youtube{
    color: red;
}

.insta{
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
} */

.faIcon:hover{
    transform: scale(1.1);
}

.copyright{
    margin-top: 50px;
    font-size: x-small;
}