.nav {
  color: #202020;
  background-color: hsla(0,0%,100%,.8);
}

.logo {
  transform-origin: 65% 50%;
  transform: rotate(-5deg);
  letter-spacing: 0.2rem;
  padding: .5rem 0 1.5rem 0;
}

.row {
  display: flex;
  justify-content: center;
}

.logo__title {
  font-size: 1.5rem;
  font-weight: 600;
  font-style: oblique;
  margin: 0;
}

.logo__subtitle {
  font-size: 1rem;
  font-style: italic;
  margin: 0 0 0 2.75rem;
}

.menu {
  text-align: center;
  padding-left: 0;
  /* display: none; */
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: hsla(0,0%,100%,.8);
}

.nav__item {
  list-style: none;
  padding: .225rem 0;
}

.nav__link {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  color: #202020;
}

.nav__link.active {
  text-decoration-line: underline;
  text-underline-offset: 0.5rem;
}



@media (min-width: 700px) {
  .logo {
    min-width: 281px;
    margin: 0;
    padding: 0;
  }

  .nav {
    padding: 2rem;
  }

  .menu {
    display: flex;
    gap: 2rem;
    position: inherit;
    width: auto;
    background-color: inherit;
  }

  .nav__item:hover {
    text-decoration-line: underline;
    text-underline-offset: 0.5rem;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  } 
}
